import ENV from './Env.js';





export const getMsList = () => {
  return fetch(`${ENV.API_URL}/category/ms/all`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((response) => {
    return response.json()
  });
}

export const getList = () => {
  return fetch(`${ENV.API_URL}/category/all`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((response) => {
    return response.json()
  });
}


export const makeCategory = (_api, _data) => {
  _data['api_token'] = _api;

  return fetch(ENV.API_URL + '/category/store', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(_data)
  }).then(function (response) {
    return response.json();
  });
}


export const updateCategory = (_api, _data) => {
  _data['api_token'] = _api;

  return fetch(`${ENV.API_URL}/category/update/${_data['id']}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(_data)
  }).then(function (response) {
    return response.json();
  });
}

export const getSubList = () => {
  return fetch(ENV.API_URL + '/category/sub/all', {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    
  }).then((response) => {
    return response.json()
  });
}

export const updateMsCategory = (_api, _data) => {
  _data['api_token'] = _api;

  return fetch(`${ENV.API_URL}/category/updatems/${_data['id']}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(_data)
  }).then(function (response) {
    return response.json();
  });
}